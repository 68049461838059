import Vue from "vue";
import VueRouter from "vue-router";
const Home = () => import("../views/Home");

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    component: Home
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
