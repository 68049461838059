import settings from "@/settings";

let atualizarImagemPerfil = (imagem, headers) => {
  return fetch(`${settings.apetrusApi}/pessoas/atualizarimagemperfil/`, {
    method: "PUT",
    body: JSON.stringify(imagem),
    mode: "cors",
    headers: headers
  });
};

let insert = (data, headers) => {
  return fetch(`${settings.apetrusApi}/pessoas/cadastrar/`, {
    method: "POST",
    body: JSON.stringify(data),
    mode: "cors",
    headers: headers
  });
};

let getPessoa = headers => {
  return fetch(`${settings.apetrusApi}/pessoas/`, {
    method: "GET",
    mode: "cors",
    cache: "default",
    headers: headers
  });
};

export default {
  atualizarImagemPerfil,
  insert,
  getPessoa
};
