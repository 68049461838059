import Vue from "vue";
import Vuex from "vuex";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";

import { PoliticaDePrivacidadeDao, PessoaDao } from "@/api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    user: null,
    headers: null,
    info: null,
    error: null,
    politicasDePrivacidade: null,
    pessoa: null,
    appVerifier: null,
    verificationId: null,
    dialogRecaptcha: false,
    interval: 0
  },
  mutations: {
    setAppVerifier(state, payload) {
      state.appVerifier = payload;
    },
    setVerificationId(state, payload) {
      state.verificationId = payload;
    },
    setInfo(state, payload) {
      state.info = payload;
    },
    setError(state, payload) {
      state.error = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setAuthHeader(state, payload) {
      state.headers = new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${payload}`
      });
    },
    setPoliticasDePrivacidade(state, payload) {
      state.politicasDePrivacidade = payload;
    },
    setPessoa(state, payload) {
      state.pessoa = payload;
    },
    setDialogRecaptcha(state, payload) {
      state.dialogRecaptcha = payload;
    }
  },
  actions: {
    carregarPoliticasDePrivacidade({ commit }) {
      commit("setLoading", true);
      PoliticaDePrivacidadeDao.get()
        .then(response => {
          commit("setLoading", false);
          if (response.ok) {
            return response.json();
          } else {
            throw Error(
              "Desculpe, não foi possível carregar as políticas de privacidade"
            );
          }
        })
        .then(json => {
          commit("setPoliticasDePrivacidade", json);
        })
        .catch(error => {
          commit("setError", error);
        });
    },
    renovarToken({ commit, state }) {
      let diference;
      if (state.user != null) {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then(result => {
            var expirationTime;
            expirationTime = Date.parse(result.expirationTime);
            let now = new Date().getTime();
            diference = (expirationTime - now) / 1000;
            diference = diference.toFixed(0);
          });
        state.interval = setInterval(() => {
          if (state.user != null) {
            diference--;
            if (diference === 0) {
              firebase
                .auth()
                .currentUser.getIdToken(true)
                .then(token => {
                  commit("setAuthHeader", token);
                });
              firebase
                .auth()
                .currentUser.getIdTokenResult()
                .then(result => {
                  var expirationTime;
                  expirationTime = Date.parse(result.expirationTime);
                  let now = new Date().getTime();
                  diference = (expirationTime - now) / 1000;
                  diference = diference.toFixed(0);
                });
            }
          } else clearInterval(state.interval);
        }, 1000);
      }
    },
    /* TypeError: t.getIdToken is not a function. (In 't.getIdToken()', 't.getIdToken' is undefined) */
    userChanged({ commit, state }, payload) {
      commit("setLoading", false);
      if (payload) {
        payload.getIdToken().then(token => {
          commit("setUser", payload);
          commit("setAuthHeader", token);
          clearInterval(state.interval);
          this.dispatch("renovarToken");
        });
      } else {
        commit("setUser", null);
        commit("setAuthHeader", null);
      }
    },
    async signUserIn({ commit }, payload) {
      await firebase
        .auth()
        .signInWithEmailAndPassword(payload.email, payload.password)
        .then(() => {
          firebase.auth().onAuthStateChanged(user => {
            this.dispatch("userChanged", user);
          });
        })
        .catch(error => {
          commit("setLoading", false);
          commit("setError", error);
        });
    },
    signUserOut({ commit }) {
      commit("setLoading", true);
      firebase
        .auth()
        .signOut()
        .catch(() => {});
      commit("setUser", null);
      commit("setPessoa", null);
    },
    signUserUp({ commit }, payload) {
      commit("setLoading", true);
      return new Promise((resolve, reject) => {
        firebase
          .auth()
          .createUserWithEmailAndPassword(payload.email, payload.password)
          .then(user => {
            this.dispatch("userChanged", user.user);
            resolve(user.user);
          })
          .catch(error => {
            commit("setLoading", false);
            commit("setError", error);
            reject(error);
          });
      });
    },
    carregarDadosPerfil({ commit, state }) {
      return new Promise((resolve, reject) => {
        PessoaDao.getPessoa(state.headers)
          .then(response => {
            if (!response.ok) {
              resolve(false);
            }
            commit("setPessoa", null);
            return response.json();
          })
          .then(json => {
            commit("setPessoa", json);
            resolve(true);
          })
          .catch(() => {
            reject(new Error("Não achou pessoa com esse email"));
          });
      });
    },
    inserirPessoa({ commit, state }, payload) {
      commit("setLoading", true);
      return new Promise((resolve, reject) => {
        PessoaDao.insert(payload, state.headers)
          .then(response => {
            commit("setLoading", false);
            if (!response.ok) {
              if (response.status === 422) {
                throw Error(
                  "Desculpe, já possuímos um usuário cadastrado com esse CPF"
                );
              } else {
                throw Error(
                  "Houve uma falha em seu cadastro, por favor, tente novamente."
                );
              }
            } else {
              return response.json();
            }
          })
          .then(json => {
            commit("setPessoa", json);
            resolve(json);
          })
          .catch(error => {
            commit("setError", error);
            reject(error);
          });
      });
    },
    openDialogRecaptcha({ commit }) {
      commit("setDialogRecaptcha", true);
    },
    atualizarFotoPerfil({ commit, state }, payload) {
      commit("setLoading", true);
      let storageRef = firebase.storage().ref();
      let imageRef = storageRef.child(
        `users/avatars/${state.pessoa.id_pessoa}.png`
      );
      imageRef.putString(payload, "data_url").then(snapshot => {
        snapshot.ref.getDownloadURL().then(downloadURL => {
          const imagem = {
            url_imagem_perfil: downloadURL
          };
          return new Promise((resolve, reject) => {
            PessoaDao.atualizarImagemPerfil(imagem, state.headers)
              .then(response => {
                commit("setLoading", false);
                if (response.ok) {
                  resolve(response);
                } else {
                  throw Error(
                    "Desculpe, mas não foi possível vincular a imagem de perfil ao usuário."
                  );
                }
              })
              .catch(error => {
                commit("setError", error);
                reject(error);
              });
          });
        });
      });
    },
    sendSmsVerification({ commit, state }, payload) {
      commit("setLoading", true);

      if (!state.appVerifier) {
        commit(
          "setAppVerifier",
          new firebase.auth.RecaptchaVerifier(payload.firebaseContainer)
        );
      }
      var provider = new firebase.auth.PhoneAuthProvider();
      let phoneNumber = `+55${payload.telefone}`;
      return new Promise((resolve, reject) => {
        provider
          .verifyPhoneNumber(phoneNumber, state.appVerifier)
          .then(verificationId => {
            commit("setLoading", false);
            commit("setDialogRecaptcha", false);
            commit("setVerificationId", verificationId);
            resolve(verificationId);
          })
          .catch(error => {
            commit("setLoading", false);
            commit("setDialogRecaptcha", false);
            commit("setError", error);
            reject(error);
          });
      });
    },
    confirmSmsVerification({ commit, state }, payload) {
      commit("setLoading", true);
      var phoneCredential = firebase.auth.PhoneAuthProvider.credential(
        state.verificationId,
        payload
      );
      return new Promise((resolve, reject) => {
        firebase
          .auth()
          .currentUser.updatePhoneNumber(phoneCredential)
          .then(() => {
            commit("setLoading", false);
            resolve(true);
          })
          .catch(error => {
            commit("setLoading", false);
            commit("setError", error);
            reject(error);
          });
      });
    }
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    info(state) {
      return state.info;
    },
    error(state) {
      return state.error;
    },
    politicasDePrivacidade(state) {
      return state.politicasDePrivacidade;
    },
    dialogRecaptcha(state) {
      return state.dialogRecaptcha;
    },
    pessoa(state) {
      return state.pessoa;
    },
    user(state) {
      return state.user;
    }
  }
});
