import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import firebase from "firebase/app";
import "firebase/auth";
import settings from "@/settings";
import VueCroppie from "vue-croppie";
import "croppie/croppie.css";
import VueTheMask from "vue-the-mask";

Vue.config.productionTip = false;

Vue.use(VueTheMask);

Vue.use(VueCroppie);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  created() {
    firebase.initializeApp(settings.firebaseConfig);
  },
  mounted() {
    firebase.auth().onAuthStateChanged(user => {
      this.$store.dispatch("userChanged", user);
    });
  }
}).$mount("#app");
